$(document).ready(function(){
    render();

    $('.mobile-btn').on('click', mobile);

    $('.mobile-menu .drop a .tri').on('click', function(e){
        e.preventDefault();

        var $this = $(this).closest('.drop');
        var ul = $this.find('ul');

        if($this.hasClass('active'))
        {
            $this.removeClass('active');
            ul.slideUp();
        }
        else
        {
            $('.mobile-menu .drop').removeClass('active');
            $('.mobile-menu .drop').find('ul').slideUp();
            $this.addClass('active');
            ul.slideDown('ul');
        }
    });

    $('.gallery-filter').on('change', function(){
        $(this).closest('form').submit();
    });

    var formInputs = $('.contact form').find('input, textarea');

    formInputs.each(function(){
        var hold = $(this).data('val');

        if($(this).val() == '')
        {
            $(this).val(hold);
            $(this).attr('placeholder', hold);
        }
    });

    formInputs.on('focus', function(){
        if($(this).data('val') == $(this).val())
        {
            $(this).val('');
        }
    });

    formInputs.on('focusout', function(){
        if($(this).val() == '')
        {
            $(this).val($(this).data('val'));
        }
    });

    $('.select-drop').on('click', 'li', function(){
        var $this = $(this);
        var input = $this.closest('.select-container').find('input');
        var name = input.data('input');
        var val = $this.text();

        console.log(input);

        input.val(val);
        $('input[name="'+name+'"]').val(val);
    });

    $(window).on('resize', function(){
        render();
    });

    function render()
    {
        var wh = $(window).height();

        $('.mobile-btn').removeClass('active');
        $('.mobile-menu').removeClass('active');
    }

    function mobile()
    {
        var btn = $('.mobile-btn');
        var menu = $('.mobile-menu');

        if(btn.hasClass('active'))
        {
            btn.removeClass('active');
            menu.removeClass('active');
        }
        else
        {
            btn.addClass('active');
            menu.addClass('active');
        }
    }
});
